.alignleft, .alignright, .alignnone {
  width: 100%;
  margin: 0 0 15px;
  padding: 5px;
  border: 1px solid #333333;

  @include tablet {
    width: auto;
  }
}

.alignleft {

  @include tablet {
    margin: 0 15px 5px 0;
  }
}

.alignright {

  @include tablet {
    margin: 0 0 5px 15px;
  }
}
