.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0;
    background: $dark-gray;
    transition: all 0.4s ease;
    overflow-y: scroll;

    a {
        display: block;
        color: #fff;
        font-size: 14px;
        padding: 15px 20px;
        border-bottom: 1px solid darken($dark-gray, 2%);
    }

    &.sideNav-open {
        #{$sideNavDirection}: 0px;
    }

    li.dropdown {

      a.dropdown-toggle:after {
        vertical-align: middle;
      }

      .dropdown-menu {
        transform: none !important;
        position: relative !important;
        float: none;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 0;

        li {

          a {
            background-color: darken($dark-gray, 2%);
            border-color: darken($dark-gray, 3%);
          }
        }
      }

      &.show {
        a.dropdown-toggle:after {
          border-top: 0;
          border-bottom: .3em solid;
        }
      }
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.4s ease;

    &:before, .banner:before {
      transition: all 0.4s ease;
      opacity: 0;
      content: '';
      background-color: rgba($black, .5);
      @include stretch;
      z-index: 1000;
      visibility: hidden;
    }
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;

    &:before, .banner:before {
      opacity: 1;
      visibility: visible;
    }
}
