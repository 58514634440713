// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend a.btn;
  @extend .btn-primary;
}

.alert-warning {
  @extend .card-block;
  @extend .small;
  @extend .light;
  border: 0;
  font-size: 14px;
  color: $white;
}


.search-form {

  label {
    display: block;
  }

  .search-field
   {
    background: $white;
    color: $dark-gray !important;
    border: 1px solid #000000;
    font-family: $secondary-font;
    color: $white;
    letter-spacing: -1px;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    padding: 8px 10px 5px;
    min-width: 100%;
    max-width: 100%;
    border-radius: 0;

    @include laptop {
      min-width: 258px;
    }
  }

  ::-webkit-input-placeholder { /* Chrome */
    color: $dark-gray;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $dark-gray;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $dark-gray;
    opacity: 1;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: $dark-gray;
    opacity: 1;
  }

  .search-submit {
    cursor: pointer;
    padding: 10px 10px 5px;
    font-size: 17px;
    line-height: 22px;
  }
}


.gform_wrapper {
  ul {
    list-style: none;
    padding: 0;
  }

  .gfield {
    margin-bottom: 15px;

    label {
      font-family: $primary-font;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }

    input,
    textarea {
      border: none;
      padding: 12px 10px;
      width: 100%;
      font-size: 18px;
    }

    textarea {
      height: 180px;
    }
  }

  .gform_footer {

    input[type=submit] {
      -webkit-appearance: none;
      -moz-appearance: none;
      @extend a.btn;
      @extend .btn-primary;
      cursor: pointer;
    }
  }

  .g-recaptcha label {
    @extend .sr-only;
  }
}
