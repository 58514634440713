// Category Styles
.category-card, .product-card, .search-card {
  display: block;

  .featured-image-container {
    border: 1px solid #333333;
    margin: 0 0 15px;
    padding: 5px;

    .featured-image {
      display: block;
      width: 100%;
      @include aspect-ratio(4,3);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.category-card {
  display: block;
  text-align: center;
  margin: 0 0 20px;

  .featured-image {
    background-image: url('../images/coming_soon.jpg');
  }
}

.product-card, .search-card {
  margin: 0 0 30px;

  h2.entry-title {
    @extend h3;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-weight: 700;
  }

  .product-link {
    display: block;
  }
}

.search-card {

  .featured-image-container {

    @include tablet {
      margin: 0;
    }
  }
}


// Product Styles

.single-store {

  h1.entry-title {
    font-size: 30px;
  }

  .product-details {
    margin: 0 0 30px;

    .featured-image-container {
      display: block;
      text-align: center;
      margin: 0 0 15px;

      .featured-image {
        margin: 0 0 10px;
        width: 100%;
      }
    }

    .stock-title {
      text-decoration: underline;
    }

    a.btn, input.wspsc_add_cart_submit {
      width: 100% !important;
      transition: $base-transition;
    }
  }
}


// Breadcrumb styles

.breadcrumbs {
  display: block;
  padding: 11px 10px;
  margin: 0 0 15px;
  background-color: $dark-gray;
  font-size: 13px;
  font-weight: 400;
  color: #ccc;

  a {
    border: 0;
    color: $white;

    &:hover {
      color: $brand-primary;
    }
  }

  span[property="itemListElement"] {
    margin: 0 2px;

    @include phablet {
      margin: 0 3px;
    }
  }
}


// Product Gallery Styles

.gallery-card {
  margin: 0;
}

.page-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;

  @include desktop {
    margin-top: 0;
  }

  .gallery-col {
    position: relative;
    display: block;
    padding: 10px;
    @include flex(0, 0, 50%);

    @include tablet {
       @include flex(0, 0, 50%);
    }

    @include laptop {
       @include flex(0, 0, calc(1 / 3 * 100%));
    }

    img {
      width: 100%;
    }

    &:before {
      content: '';
      @include stretch(5px);
      border: 1px solid #333333;
      user-select: none;
      pointer-events: none;
    }
  }

  .hide-temp {
    @include tablet {
      display: none!important;
    }
    @include laptop {
      display: block!important;
    }
  }

  .show-temp {
    display: none!important;
    @include tablet {
      display: block!important;
    }
    @include laptop {
      display: none!important;
    }
  }

  .gallery-image {
    position: relative;
    display: block;
    @include aspect-ratio(4,3);
    background-size: cover;
    background-repeat: no-repeat;

    &.hide-image {
      display: none;
    }

    img {
      display: none;
    }

    &:after {
      content: '';
      @include stretch();
      background-color: rgba($black, 0.7);
      transition: $base-transition;
      will-change: opacity;
      visibility: hidden;
      opacity: 0;
    }

    .fa {
      transition: $base-transition;
      position: absolute;
      z-index: 300;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      text-align: center;
      top: 50%;
      font-size: 20px;
      color: $white;
      will-change: opacity;
      visibility: hidden;
      opacity: 0;
    }

    &:focus,
    &:hover {
       &:after,
      .fa {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .open-gallery {
    @include aspect-ratio(4, 3);
    background-color: $brand-primary;
    transition: $base-transition;
    will-change: background-color;
    display: block;

    span {
      position: absolute;
      z-index: 300;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      text-align: center;
      top: 50%;
      font-size: 20px;
      color: $white;
      font-weight: 500;

      small {
        display: block;
      }
    }

    &:focus,
    &:hover {
      background-color: darken($brand-primary, 10%);
    }
  }
}


// Pagination Styles

.primary_pagination {
  display: block;
  margin: 0 -1px;

  .current, a {
    color: $white;
    font-weight: 600;
    display: inline-block;
    padding: 6px 16px;
    font-size: 14px;
    margin: 3px 1px;

    @include laptop {
      padding: 7px 15px;
      font-size: 15px;
    }
  }

  .current {
    background-color: $gray;
    color: white;
  }

  a {
    background-color: $brand-primary;

    &:hover {
      background-color: darken($brand-primary, 10%);
    }
  }
}
