$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";

// Colors
$black:                 #000000;
$white:                 #ffffff;
$gray-100:              #f8f9fa;
$gray-600:              #868e96;
$gray-800:              #343a40;
$red:                   #dc3545;
$yellow:                #ffc107;
$green:                 #28a745;
$cyan:                  #17a2b8;

$brand-primary:         #9A1C1F;
$purple:                #d044af;
$dark-gray:             #1a1a1a;
$gray:                  lighten($dark-gray, 3%);

$theme-colors: (
  primary:              $brand-primary,
  secondary:            $gray-600,
  success:              $green,
  info:                 $cyan,
  warning:              $yellow,
  danger:               $red,
  light:                $gray-100,
  dark:                 $gray-800
);


// Fonts
$primary-font:          "Trebuchet MS", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
$secondary-font:        "Crimson Text", "Times New Roman", serif; // 400, 600, 700
$body-font-size:        14px;
$body-font-weight:      700;
$body-font:             $primary-font;
$body-color:            $white;
$heading-font:          $secondary-font;
$heading-color:         $brand-primary;
$h1-weight:             600;
$h1-size:               36px;
$h2-weight:             600;
$h2-size:               24px;
$h3-weight:             600;
$h3-size:               18px;

// Base Styles
$base-transition:       0.3s ease-in-out;
$section-padding:       30px;


//Side-Nav
$sideNavWidth: 260px;
$sideNavDirection: (right);

$button_width: 40px;     // width of button area
$button_height: 110px;     // height of button area
$bar_thickness: 6px;     // thickness of button bars
$bar_roundness: 0px;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 8px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: $white;
$hamburger_active: $white;


// Bootstrap Variables
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
);


// Breakpoint variables
$width_mobile:          480px;
$width_phablet:         576px;
$width_tablet:          768px;
$width_laptop:          992px;
$width_desktop:         1200px;

/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }


@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}


@mixin stretch($offset: 0) {
  position: absolute;
  top: #{$offset};
  right: #{$offset};
  left: #{$offset};
  bottom: #{$offset};
  z-index: 99;
}


@mixin smooth-fonts() {
  -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}


@mixin letter-spacing($letter-spacing: 0) {
  letter-spacing: calc(#{$letter-spacing} * 0.001em);
}


// Flexbox mixin - prevents most IE 11 issues
@mixin flex($grow: 0, $shrink: 0, $basis: 100%) {
  flex: #{$grow} #{$shrink} #{$basis};
  max-width: #{$basis};
}


 // Padding Offset Mixin
@mixin padding-offset($padding: 0, $selector: '') {
  margin-left: -#{$padding};
  margin-right: -#{$padding};
  #{$selector} {
    padding-left: #{$padding};
    padding-right: #{$padding};
  }
}
