a.btn {
  border: 0;
  border-radius: 0;
  font-family: $secondary-font;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 16px 20px 12px;
  cursor: pointer;

  .fa {
    margin-right: 3px;
  }

  &.btn-primary {
    background-color: $brand-primary;

    &:hover {
      background-color: darken($brand-primary, 10%);
    }
  }

  &.small {
    padding: 14px 18px 10px;
    font-size: 16px;
  }
}
