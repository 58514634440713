.footer {
  padding: 15px 0 30px;
  text-align: center;

  p, a {
    color: $white;
  }

  p {
    margin: 0;
  }
}
