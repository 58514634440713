.sidebar {

  .category-menu {
    margin: 0 0 30px;
    padding: 0;
    list-style-type: none;

    li {
      padding-left: 20px;
      background: url('../images/bullet.png') 0 4px no-repeat;
      margin-bottom: 0.5em;

      a {
        font-size: 1.2em;
        line-height: 1.1em;
        color: $white;

        .fa {
          margin-right: 3px;
        }

        &:hover {
          border-bottom: 1px solid;
        }
      }
    }
  }

  .cart {
    margin: 0 0 30px;
  }


  .mc4wp-form-fields {

    input {
      width: 100%;
      border: 0;
      padding: 8px 10px;
      margin: 0 0 5px;
    }

    input[type='submit'] {
      width: auto;
      @extend a.btn;
      @extend .btn-primary;
      @extend .small;
    }
  }
}
