html {
   display: flex;
 }

 body.sideNavBody { // any body class found on every page can be used here, this keeps styles from effecting the Wordpress editor
   min-height: 100vh;
   flex: 1 1 auto;
   display: flex;
   flex-flow: column nowrap;
   background: $dark-gray url('../images/site_bg.jpg') 50% 0 no-repeat;
 }

 .wrap {
   flex: 1 1 auto;
 }


html, body {
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  font-family: $body-font;
  color: $body-color;
  @include smooth-fonts;
}

img {
  max-width: 100%;
  height: auto;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-variant: small-caps;
  letter-spacing: -2px;
}

h1, .h1 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h1-weight;
  font-size: 30px;
  margin: 0 0 15px;

  @include tablet {
    font-size: $h1-size;
  }
}

h2, .h2 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h2-weight;
  font-size: $h2-size;
  margin: 0 0 10px;
}

h3, .h3 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h3-weight;
  font-size: $h3-size;
}

p, li {
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: 1.5em;
}

a {
  transition: $base-transition;
  color: $purple;

  &:hover, &:focus {
    text-decoration: none;
    color: $brand-primary;
  }
}

ul.nav {
  list-style-type: none;
  padding: 0;

  .sub-menu {
    list-style-type: none;
    padding: 0;
  }
}

.card-block {
  display: block;
  margin: 0 0 20px;
  padding: 20px;
  background-color: $dark-gray;

  @include laptop {
    padding: 25px;
    margin: 0 0 30px;
  }

  &.light {
    background-color: $gray;
  }

  &.small {
    padding: 20px;
    margin: 0 0 20px;
  }
}

.page-images {
  margin: 0 0 20px;
  text-align: center;

  @include laptop {
    margin: 0 0 30px;
  }

  .pgae-banner {
    width: 100%;
    text-align: center;

    @include tablet {
      width: auto;
    }
  }

  @include tablet {

    .left {
      text-align: left;
    }

    .right {
      text-align: right;
    }
  }
}


.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 20px;

  iframe, object, embed, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
.lg-outer .lg-item {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}
