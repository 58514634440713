.banner {
  padding: 15px 0;

  .logo {
    width: 175px;

    @include tablet {
      width: 240px;
    }
  }

  .banner-content {
    padding: 20px 0 15px;

    @include laptop {
      padding: 50px 0 0;
    }

    .header-desc {
      font-family: $secondary-font;
      font-size: 24px;
      line-height: 1em;
      font-weight: 700;
      font-style: italic;
      font-variant: small-caps;
      letter-spacing: -1px;
      text-shadow: 2px 2px 2px #000000;
      margin: 0 0 20px;
      text-align: center;

      @include tablet {
        font-size: 28px;
        margin: 0;
        text-align: left;
      }
    }

    .header-btn-container {
      text-align: center;
      margin: 0 0 30px;
    }

    .search-form {
      display: block;

      label {
        margin: 0;
      }

      .search-field {
        background: $dark-gray;
        color: $white !important;
        min-width: 100%;

        @include laptop {
          min-width: 258px;
        }
      }

      .search-submit {
        display: none;
      }

      ::-webkit-input-placeholder { /* Chrome */
        color: $white;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: $white;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: $white;
        opacity: 1;
      }
      :-moz-placeholder { /* Firefox 4 - 18 */
        color: $white;
        opacity: 1;
      }
    }


  }

  .navigation {
    list-style-type: none;
    background: url(../images/nav-bg.png) -90px 50% no-repeat;
    margin: -5px -30px 0;
    padding: 30px 15px;

    li {
      display: inline-block;
      padding: 0 15px;

      a {
        position: relative;
        color: $white;
        font-size: 22px;
        line-height: 1em;
        text-transform: uppercase;
        font-family: $secondary-font;
        font-weight: 700;
        z-index: 1;

        &:before {
          content: url(../images/menu-hover.png);
          position: absolute;
          display: block;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
          opacity: 0;
        }
      }

      a:hover:before, &.current-menu-item a:before {
        opacity: 1;
      }

      .sub-menu {
        display: none;
      }
    }
  }
}
