.slider-section {
  margin: 0 0 15px;

  .portfolio-slider {
    position: relative;
    margin: 0 0 5px;

    .slide {
      position: relative;

      img {
        width: 100%;
      }

      .title, .content {
        position: absolute;
        z-index: 99;
      }

      .title {
        top: 10px;
        right: 15px;
        font-family: $secondary-font;
        font-variant: small-caps;
        font-size: 26px;
        font-weight: 600;
        color: $brand-primary;
        line-height: 1em;
        text-decoration: none;

        @include mobile {
          font-size: 35px;
          top: 20px;
          right: 30px;
        }

        @include tablet {
          font-size: 50px;
        }
      }

      .content {
        top: 35px;
        left: 15px;
        font-size: 13px;
        line-height: 1.2em;

        @include mobile {
          display: block;
          font-size: 16px;
          top: 55px;
        }

        @include tablet {
          font-size: 20px;
          top: 75px;
          left: 30px;
        }
      }
    }
  }

  .slider-nav {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    display: inline-block;
    min-height: 30px;

    .slick-arrow {
      position: absolute;
      top: 0;
      z-index: 1000;
      cursor: pointer;

      &:before {
        color: $brand-primary;
        font-family: FontAwesome;
        font-size: 34px;
        line-height: 23px;
        vertical-align: middle;
        opacity: 1;

        @include tablet {
          font-size: 26px;
          opacity: .55;
        }
      }

      &:hover:before {
        opacity: 1;
      }

      &.arrow-left {
        left: -25px;

        &:before {
          content: '\f0d9';
        }
      }

      &.arrow-right {
        right: -25px;

        &:before {
          content: '\f0da';
        }
      }
    }

    .slick-dots {
      position: relative;
      top: -5px;

      li {
        margin: 0 6px;

        button {

          &:before {
            content: url('../images/slider-icon.png');
            opacity: .55;
          }
        }

        &.slick-active button:before, button:hover:before {
          opacity: 1;
        }
      }
    }
  }
}
